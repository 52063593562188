body {
  text-align: center;
  background: #fafafa;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  position: relative;
}

.top {
  width: 100%;
  max-height: 100px;
}

.top h1 {
  color: #0000004d;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 32px;
}

.top button {
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #0000001a;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  text-decoration: none;
  position: absolute;
  top: 10px;
  right: 40px;
}

.top button a {
  color: inherit;
  text-decoration: none;
}

.top .chart {
  right: 90px;
}

.container {
  padding: 0 3vw;
}

.mood-chart {
  width: calc(94vw - 16px);
  min-width: 400px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  max-height: calc(100vh - 80px);
  height: calc(100vh - 125px);
  background: #fff;
  border-radius: 4px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  place-items: stretch stretch;
  padding: 8px;
  display: grid;
  position: relative;
  box-shadow: 0 4px 12px #0000003b;
}

.mood-quadrant {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  place-items: stretch stretch;
  display: grid;
  position: relative;
}

.mood {
  min-height: 50px;
  min-width: 115px;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  place-self: stretch stretch;
  font-size: 16px;
  font-weight: bold;
  transition: all .12s linear;
  position: relative;
}

.mood:hover {
  color: #fff;
  transform: scale(1.05);
  box-shadow: 0 2px 2px #0000003b;
}

.mood .on-fire {
  position: absolute;
  bottom: 0;
  right: 0;
}

.red {
  color: #ffffffe6;
  background: #d81646;
}

.rainbow {
  color: #fff;
  -z-animation: rainbow 18s ease infinite;
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3) 0 0 / 1800% 1800%;
  animation: 18s infinite rainbow;
}

@keyframes rainbow {
  0% {
    background-position: 0 82%;
  }

  50% {
    background-position: 100% 19%;
  }

  100% {
    background-position: 0 82%;
  }
}

.yellow {
  color: #000000b8;
  background: #ffd421;
}

.yellow:hover {
  color: #000000f5;
}

.blue {
  color: #ffffffe6;
  background: #5252c9;
}

.green {
  color: #ffffffe6;
  background: #23b75a;
}

.selected {
  color: #fff;
  z-index: 2;
  background: #000;
  outline: none;
  transform: scale(1.02);
}

.selected:after {
  content: attr(data-content);
  color: #000;
  z-index: 2;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 99px;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  position: absolute;
  top: -4px;
  right: 4px;
}

.selected:hover {
  color: #fff;
}

.mood:before {
  content: attr(data-tip);
  z-index: 2;
  opacity: .4;
  border-radius: 2px;
  padding: 1px 4px;
  font-size: 10px;
  line-height: 14px;
  position: absolute;
  top: 0;
  left: 0;
}

.show-tip .mood:before {
  opacity: 1;
}

[data-highlight="R"] .mood:not(.red), [data-highlight="Y"] .mood:not(.yellow), [data-highlight="B"] .mood:not(.blue), [data-highlight="G"] .mood:not(.green) {
  opacity: .7;
}

[data-highlight="R"] .mood.red:before, [data-highlight="Y"] .mood.yellow:before, [data-highlight="B"] .mood.blue:before, [data-highlight="G"] .mood.green:before {
  opacity: 1;
}

.tips {
  color: #fff;
  text-align: left;
  z-index: 3;
  background: #000000e6;
  border-radius: 4px;
  padding: 10px;
  display: none;
  position: fixed;
  top: 70px;
  right: 12px;
  box-shadow: 0 4px 12px #0000003b;
}

.tips.show {
  display: block;
}

.tips p {
  margin-top: 0;
}

@media (max-width: 1300px) {
  .mood-chart {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .mood-quadrant {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 1100px) {
  .mood-quadrant {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .mood-quadrant {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .mood-chart {
    grid-template-columns: 1fr;
  }

  .mood-quadrant {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/*# sourceMappingURL=index.579193ef.css.map */
