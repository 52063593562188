body {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0;
  background: #fafafa;
  padding: 0;
  position: relative;
}

.top {
  width: 100%;
  max-height: 100px;
}
.top h1 {
  font-size: 32px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: rgba(0, 0, 0, 0.3);
}
.top button {
  position: absolute;
  top: 10px;
  right: 40px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.top button a {
  text-decoration: none;
  color: inherit;
}
.top .chart {
  right: 90px;
}

.container {
  padding: 0 3vw;
}

.mood-chart {
  width: calc(94vw - 16px);
  min-width: 400px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-items: stretch;
  align-items: stretch;
  padding: 8px;
  max-height: calc(100vh - 80px);
  border-radius: 4px;
  background: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.23);
  height: calc(100vh - 125px);
}

.mood-quadrant {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-items: stretch;
  align-items: stretch;
}

.mood {
  border: none;
  min-height: 50px;
  min-width: 115px;
  justify-self: stretch;
  align-self: stretch;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  box-sizing: border-box;
  border-radius: 2px;
  transition: 0.12s linear;
  cursor: pointer;
}
.mood:hover {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.23);
  color: white;
  transform: scale(1.05);
}
.mood .on-fire {
  position: absolute;
  bottom: 0;
  right: 0;
}

.red {
  background: #D81646;
  color: rgba(255, 255, 255, 0.9);
}

.rainbow {
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
  color: white;
  background-size: 1800% 1800%;
  -webkit-animation: rainbow 18s ease infinite;
  -z-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
.yellow {
  background: #FFD421;
  color: rgba(0, 0, 0, 0.72);
}
.yellow:hover {
  color: rgba(0, 0, 0, 0.96);
}

.blue {
  background: #5252C9;
  color: rgba(255, 255, 255, 0.9);
}

.green {
  background: #23B75A;
  color: rgba(255, 255, 255, 0.9);
}

.selected {
  background: black;
  color: white;
  transform: scale(1.02);
  z-index: 2;
  outline: none;
}
.selected::after {
  content: attr(data-content);
  color: black;
  font-size: 10px;
  background-color: white;
  border: 1px solid black;
  line-height: 16px;
  padding: 2px 4px;
  border-radius: 99px;
  font-weight: bold;
  position: absolute;
  top: -4px;
  right: 4px;
  z-index: 2;
}
.selected:hover {
  color: white;
}

.mood::before {
  content: attr(data-tip);
  font-size: 10px;
  line-height: 14px;
  padding: 1px 4px;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.4;
}

.show-tip .mood::before {
  opacity: 1;
}

[data-highlight=R] .mood:not(.red),
[data-highlight=Y] .mood:not(.yellow),
[data-highlight=B] .mood:not(.blue),
[data-highlight=G] .mood:not(.green) {
  opacity: 0.7;
}

[data-highlight=R] .mood.red::before,
[data-highlight=Y] .mood.yellow::before,
[data-highlight=B] .mood.blue::before,
[data-highlight=G] .mood.green::before {
  opacity: 1;
}

.tips {
  display: none;
  position: fixed;
  color: #ffffff;
  text-align: left;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.23);
  top: 70px;
  right: 12px;
  padding: 10px;
  z-index: 3;
}
.tips.show {
  display: block;
}
.tips p {
  margin-top: 0;
}

@media all and (max-width: 1300px) {
  .mood-chart {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  .mood-quadrant {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media all and (max-width: 1100px) {
  .mood-quadrant {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media all and (max-width: 900px) {
  .mood-quadrant {
    grid-template-columns: 1fr 1fr;
  }
}
@media all and (max-width: 600px) {
  .mood-chart {
    grid-template-columns: 1fr;
  }

  .mood-quadrant {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
